import React, { useEffect, useState } from 'react'
import { Root } from 'templates/layout'
import Preloader from 'components/Preloader'

const PresentationPageTmpl = ({ pdf, ios_pdf, android_pdf }) => {
	let [isMobile, setIsMobile] = useState(true),
		[isAndroid, setAndroid] = useState(null)

	useEffect(() => {
		if (window.innerWidth < 1024) {
			setAndroid(false)
			if (navigator.platform === 'iPhone') {
				setTimeout(() => {
					window.location.replace(ios_pdf)
				}, 4000)
			} else {
				setAndroid(true)
				setTimeout(() => {
					window.location.replace(android_pdf)
				}, 4000)
			}

			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}, [pdf, ios_pdf, android_pdf])

	return isMobile ? (
		isAndroid ? (
			<div className="flex justify-center pt-10 pb-5">
				<Preloader side={220} />
			</div>
		) : (
			''
		)
	) : (
		<Root
			desktopCtaVariant="hidden"
			desktopWordmarkPosition="hidden"
			hideFooter={true}
		>
			<section id="pdf" className="relative h-screen w-full pt-1 lg:py-7 lg:pb-0 ">
				<embed
					type="application/pdf"
					style={{
						margin: '0 auto',
						width: '100%',
						height: '100%',
					}}
					src={pdf + '#embedded=true&view=fitH&toolbar=0'}
					allowFullScreen
				></embed>
			</section>
		</Root>
	)
}
export default PresentationPageTmpl
