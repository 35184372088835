import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

function Preloader({ color, side }) {
	const faceRots = [
		{ ry: 0, rx: 0 },
		{ ry: 90, rx: 0 },
		{ ry: 180, rx: 0 },
		{ ry: 270, rx: 0 },
		{ ry: 0, rx: 90 },
		{ ry: 0, rx: -90 },
	]

	const faceRefs = faceRots.map(() => createRef())

	const sideProps = {
			style: {
				width: side,
				height: side,
				transformStyle: 'preserve-3d',
			},
		},
		colorProps = {
			style: {
				backgroundColor: color,
			},
		}

	useEffect(() => {
		faceRefs.forEach(
			(faceRef, i) => {
				gsap.set(faceRef.current, {
					rotateY: faceRots[i].ry,
					rotateX: faceRots[i].rx,
					transformOrigin: `50% 50% -${side / 2}px`,
					transformStyle: 'preserve-3d',
					z: side / 2,
				})
			},
			[faceRefs]
		)

		const rotationTl = gsap
			.timeline({
				repeat: -1,
				yoyo: true,
				repeatDelay: 1,
				delay: 1,
				defaults: { duration: 0.8, ease: 'elastic.out(0.9, 0.9)' },
			})
			.to('#preloader-cube', { rotateX: -90, rotateY: 0 })
			.to('#preloader-cube', { rotateX: -180, rotateY: 0 })
			.to('#preloader-cube', {
				rotateX: -180,
				rotateY: 90,
				duration: 1.4,
				ease: 'elastic.out(0.8, 0.6)',
			})

		const loadingTl = gsap
			.timeline({
				repeat: -1,
				yoyo: true,
				repeatDelay: 1,
				delay: 1,
				defaults: { duration: 1, ease: 'power1.out' },
			})
			.to('.preloader-title', { opacity: 0 })

		return () => {
			loadingTl.kill()
			rotationTl.kill()
		}
	})

	return (
		<div
			className="preloader-container mt-15 lg:mt-20"
			style={{ perspective: '1000px' }}
		>
			<div id="preloader-cube" {...sideProps}>
				{faceRefs.map((elem, index) => (
					<div
						ref={faceRefs[index]}
						className={'absolute h-full w-full rounded-[7px]'}
						{...colorProps}
						key={index}
					></div>
				))}
			</div>
			<h2
				className="preloader-title mt-10 w-full text-center text-2xl font-bold leading-relaxed"
				style={{ color }}
			>
				Loading
			</h2>
		</div>
	)
}

export default Preloader

Preloader.propTypes = {
	side: PropTypes.number,
	color: PropTypes.string.isRequired,
}

Preloader.defaultProps = {
	color: 'black',
}
