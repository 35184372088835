import React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import PresentationPageTmpl from 'templates/presentation/page'
import { graphql } from 'gatsby'

function PresentationsPage({ data: { prismicPresentation } }) {
	const content = {
		pdf: prismicPresentation.data.pdf?.url,
		ios_pdf: prismicPresentation.data.ios_pdf?.url,
		android_pdf: prismicPresentation.data.android_pdf?.url,
	}

	return <PresentationPageTmpl {...content} />
}

export const query = graphql`
	query presentationPageQuery($uid: String) {
		prismicPresentation(uid: { eq: $uid }) {
			_previewable
			url
			uid
			data {
				pdf {
					url
				}
				ios_pdf {
					url
				}
				android_pdf {
					url
				}
			}
		}
	}
`

export default withPrismicPreview(PresentationsPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
